// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'polaris-c4a50',
    appId: '1:820592999404:web:c09d76f229050ef8745ec9',
    storageBucket: 'polaris-c4a50.appspot.com',
    locationId: 'asia-southeast1',
    apiKey: 'AIzaSyBOd6XSm7addJajd0nehq1ppjPjjwaMl9g',
    authDomain: 'polaris-c4a50.firebaseapp.com',
    messagingSenderId: '820592999404',
    measurementId: 'G-CJEBXP9E7J',
  },
  production: false,
  recaptchaKey: "6LesX3QhAAAAADNJMoAFMnw661sIVdoUjDUhI-Mm",
  serviceUrl: 'https://polaris.kobotogether.com/api/web-admin',
  appUrl: 'https://subscription.bonuslink.my',
  apiUrl: 'https://strapi.bonuslink.my',
  appData: {
    appVersion: 1,
    appId: 8,
    appName: 'SUBSCRIBER',
    appLabel: 'T2 Subscriber - Merchant',
    client: 'subscriberMerchantPortal',
    requestTspId: 0 // this should always exist in requests from client applications ::
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
